import { FormControl, FormGroup, Validators } from '@angular/forms';

import { Component } from '@angular/core';
import { Config } from 'src/app/interfaces/config';
import { ConfigService } from '../services/config.service';
import { FormState } from 'src/app/interfaces/formState';
import { Router } from '@angular/router';

@Component({
  selector: 'app-config-form',
  templateUrl: './config-form.component.html',
  styleUrls: ['./config-form.component.css'],
})
export class ConfigFormComponent {
  displayError = false;
  errorMessage = '';
  form!: FormGroup;
  formState: FormState | null = null;

  model: Config = {
    id: '',
    country_code: '',
    party_id: '',
    max_stay_minutes: null,
    min_stay_minutes: null,
    reservable_period_minutes: null,
    time_granularity_minutes: null,
    free_amendment_period_minutes: null,
    spacing_period_minutes: null,
    buffer_period_minutes: null,
    no_show_expiry_minutes: null,
  };

  config_tooltips = {
    max_stay_minutes: 'Reservations must be no longer than this.',
    min_stay_minutes: 'Reservations must be at least this long.',
    reservable_period_minutes:
      'Reservations must end no further in the future from the time they were made than this.',
    time_granularity_minutes:
      'Reservations must have their start and end times fit to this level of granularity. E.g. 30 minutes \
    would mean reservations could only start/end on the hour or at half-past the hour.',
    free_amendment_period_minutes:
      'The user will not be charged and extra fee if they amend/cancel their reservation up to this amount of \
    time before the start of the reservation. Note that there is still incentive for no-show users to cancel their reservation after this, \
    as there will be fees for both charging time and non-charging time.',
    spacing_period_minutes:
      'The period after a reservation which cannot overlap with other reservations. I.e. the end of the spacing period \
    is the earliest valid start time of the next reservation. This is to help prevent the user with the following reservation from being \
    blocked in the event that the current user overstays.',
    buffer_period_minutes:
      'The period before the reservation in which the charger is locked by a ReserveNow OCPI command. This is to prevent ad-hoc \
    users from blocking the charger when the user with the reservation shows up.',
    no_show_expiry_minutes:
      'If a user does not show up for their reservation within this amount of time after the start of their reservation, \
    their reservation will be expired and their reserved period will be made available for other users.',
  };

  constructor(
    private _configService: ConfigService,
    private _router: Router,
  ) {}

  ngOnInit() {
    if (history.state['config']) {
      this.model = history.state['config'];
    }

    console.log(this.model)

    this.formState = history.state['action'];

    this.form = new FormGroup({
      id: new FormControl(this.model.id, Validators.required),
      max_stay_minutes: new FormControl(this.model.max_stay_minutes),
      min_stay_minutes: new FormControl(this.model.min_stay_minutes),
      reservable_period_minutes: new FormControl(
        this.model.reservable_period_minutes,
      ),
      time_granularity_minutes: new FormControl(
        this.model.time_granularity_minutes,
      ),
      // TODO recontextualise this as the free_amendment_cutoff?
      free_amendment_period_minutes: new FormControl(
        this.model.free_amendment_period_minutes,
      ),
      spacing_period_minutes: new FormControl(this.model.spacing_period_minutes),
      buffer_period_minutes: new FormControl(this.model.buffer_period_minutes),
      no_show_expiry_minutes: new FormControl(this.model.no_show_expiry_minutes),
    });

    if (history.state['action'] == FormState.VIEW) {
      this.form.disable();
    }

    if (history.state['action'] == FormState.EDIT) {
      this.form.controls['id'].disable();
    }
  }

  onSubmit() {
    if (this.form.valid) {
      // Reset form errors
      this.displayError = false;
      this.errorMessage = '';

      // Update object and call service function
      this.model = Object.assign(this.model, this.form.value);
      this._configService.putConfig(this.model).subscribe({
        next: (resp) => {
          if (resp.status_code != 1000) {
            // Tracks errors returned from the server
            this.displayError = true;
            this.errorMessage = `Error ${resp.status_code}: ${resp.status_message}`;
          } else {
            this._router.navigate(['/configurations']);
          }
        },
        // Tracks errors from the service call itself i.e. server unreachable
        error: () => {
          this.displayError = true;
        },
      });
    }
  }

  resetForm() {
    this.form.reset();
  }
}
