<clr-datagrid>
  <clr-dg-column [clrDgField]="'id'" [clrDgColType]="'string'">
    ID
  </clr-dg-column>
  <clr-dg-column [clrDgField]="'user_id'" [clrDgColType]="'string'">
    USER ID
  </clr-dg-column>
  <clr-dg-column [clrDgField]="'evse_uid'" [clrDgColType]="'string'">
    EVSE UID
  </clr-dg-column>
  <clr-dg-column [clrDgField]="'status'"> Status </clr-dg-column>
  <clr-dg-column [clrDgField]="'start_time'"> Start Time </clr-dg-column>
  <clr-dg-column [clrDgField]="'end_time'"> End Time </clr-dg-column>
  <clr-dg-column [clrDgField]="'last_updated'"> Last Updated </clr-dg-column>

  <clr-dg-row *clrDgItems="let reservation of reservations">
    <clr-dg-cell>{{ reservation.id }}</clr-dg-cell>
    <clr-dg-cell>{{ reservation.user_id }}</clr-dg-cell>
    <clr-dg-cell>{{ reservation.evse_uid }}</clr-dg-cell>
    <clr-dg-cell [style.backgroundColor]="getReservationStatusColour(reservation.status)">
      {{ reservation.status }}</clr-dg-cell>
    <clr-dg-cell>{{
      reservation.start_time | date: "d/M/yy, H:mm"
    }}</clr-dg-cell>
    <clr-dg-cell>{{ reservation.end_time | date: "d/M/yy, H:mm" }}</clr-dg-cell>
    <clr-dg-cell>{{
      reservation.last_updated | date: "d/M/yy, H:mm"
    }}</clr-dg-cell>

    <!-- <clr-dg-row-detail *clrIfExpanded class="clr-row">
      <div class="clr-col-3">
        <div class="card">
          <div class="card-header">Quick look information</div>
          <div class="card-block">
            <ul class="list-unstyled">
              <li>Location: {{ reservation.location_id }}</li>
              <li>Location owner: {{ reservation.location_country_code }}-{{ reservation.location_party_id }}</li>
              <br>
              <li>Duration: {{ getReservationDuration(reservation.start_time, reservation.end_time) }} minutes</li>
              <li style="color: red;">GET BUFFER START TIME</li>
            </ul>
          </div>
          <div class="card-block" style="color: red;">
            Utilisation % ( Better at charging space list )
          </div>
        </div>
      </div>
      <div class="clr-col-6">
        <div class="card">
          <div class="card-header">Pricing information</div>
          <div class="card-block">
            <ul class="list-unstyled">
              <li>Tariff (time of reservation): {{ reservation.tariff_id }}</li>
              <li style="color: red;">TARIFF SNAPSHOT NOT PRESENT IN RES</li>
              <li></li>
              <li></li>
            </ul>
          </div>
          <div class="card-block">
            <ul class="list-unstyled">
              Reservation Periods: {{ reservation.reservation_periods }}
              <li style="color: red;">REQUIRES METHOD TO MAKE RES.PERIODS</li>
              <li></li>
            </ul>
          </div>
        </div>
      </div>
      <div class="clr-col-3">
        <div class="card">
          <div class="card-header">Actions</div>
          <div class="card-block">
            Actions you can perform on this reservation
            <br>
            <br>
            <button
              [disabled]="reservation.status == 'CANCELLED'"
              class="btn btn-warning"
              (click)="reassignChargingSpaceForReservation()"
              type="reassign"
            >
              Reassign Charging Space
            </button>
            <br>
            <br>
            <button
              [disabled]="reservation.status == 'CANCELLED'"
              class="btn btn-danger"
              (click)="cancelReservation()"
              type="cancel"
            >
              Cancel Reservation
            </button>
          </div>
        </div>
      </div>
    </clr-dg-row-detail> -->


  </clr-dg-row>
  <clr-dg-footer
    >{{ reservations.length }}
    {{
      reservations.length === 1 ? "reservation" : "reservations"
    }}</clr-dg-footer
  >
</clr-datagrid>
