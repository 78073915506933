<div class="clr-row">
  <div class="clr-col-4">
    <div class="clr-row">
      <div class="card">
        <div class="card-block">
          <div class="card-media-block">
            <div class="card-media-image circle-image">
              <cds-icon id="user-icon" shape="user" size="md"></cds-icon>
            </div>
            <div class="card-media-description">
              <span class="card-media-title">
                {{ user.firstName | titlecase }} {{ user.lastName | titlecase }}
              </span>
              <br class="title-divider" />
              <span class="card-media-text">{{ user.email }}</span>
            </div>
          </div>
          <div class="card-text">
            <table class="table table-vertical table-noborder">
              <tbody>
                <tr>
                  <th>Country code</th>
                  <td>{{ country_code }}</td>
                </tr>
                <tr>
                  <th>Party ID</th>
                  <td>{{ party_id }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="clr-row">
      <div class="card">
        <div class="card-block">
          <div class="card-media-block">
            <div class="card-media-image circle-image">
              <cds-icon
                id="reservation-icon"
                shape="clock"
                size="md"
              ></cds-icon>
            </div>

            <div class="card-media-description">
              <span class="card-media-title">
                {{ reservations_count }}
              </span>
              <span class="card-media-text"> Lifetime reservations </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="clr-row">
      <div class="card">
        <div class="card-block">
          <div class="card-media-block">
            <div class="card-media-image circle-image">
              <cds-icon id="spaces-icon" shape="bolt" size="md"></cds-icon>
            </div>
            <div class="card-media-description">
              <span class="card-media-title">{{ spaces_count }}</span>
              <span class="card-media-text"> Registered charging spaces </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="clr-col">
    <div class="card">
      <h3 class="card-header">Today's reservations</h3>
      <div class="card-block">
        <div class="card-text">
          <clr-datagrid>
            <clr-dg-column
              [clrDgField]="'id'"
              [clrDgColType]="'string'"
            >
              ID
            </clr-dg-column>
            <clr-dg-column [clrDgField]="'evse_uid'" [clrDgColType]="'string'">
              EVSE UID
            </clr-dg-column>
            <clr-dg-column
              [clrDgSortBy]="'start_time'"
              [clrDgField]="'start_time'"
            >
              Start time
            </clr-dg-column>
            <clr-dg-column [clrDgField]="'end_time'"> End time </clr-dg-column>

            <clr-dg-row *clrDgItems="let reservation of reservations">
              <clr-dg-cell>{{ reservation.id }}</clr-dg-cell>
              <clr-dg-cell>{{ reservation.evse_uid }}</clr-dg-cell>
              <clr-dg-cell>{{
                reservation.start_time | date: "d/M/yy, H:mm"
              }}</clr-dg-cell>
              <clr-dg-cell>{{
                reservation.end_time | date: "d/M/yy, H:mm"
              }}</clr-dg-cell>
            </clr-dg-row>
            <clr-dg-footer
              >{{ reservations.length }}
              {{
                reservations.length === 1 ? "reservation" : "reservations"
              }}</clr-dg-footer
            >
          </clr-datagrid>
        </div>
      </div>
    </div>
  </div>
</div>
