<a
  routerLink="/configurations/create"
  routerLinkActive="active"
  class="btn btn-sm"
>
  Add Configuration
</a>
<a
  routerLink="/configurations/assign"
  routerLinkActive="active"
  class="btn btn-sm"
>
  Assign Configuration
</a>

<clr-alert
  [clrAlertType]="'success'"
  [(clrAlertClosed)]="deleteSuccessAlertClosed"
>
  <clr-alert-item>
    <span class="alert-text">The configuration was successfully deleted.</span>
  </clr-alert-item>
</clr-alert>

<clr-alert
  [clrAlertType]="'warning'"
  [(clrAlertClosed)]="deleteFailureAlertClosed"
>
  <clr-alert-item>
    <span class="alert-text"
      >Cannot delete a configuration that is currently assigned. Unassign the
      configuration and try again.</span
    >
  </clr-alert-item>
</clr-alert>

<clr-datagrid>
  <clr-dg-column [clrDgField]="'id'">Config. ID</clr-dg-column>
  <clr-dg-column [clrDgField]="'max_stay_minutes'" [clrDgColType]="'number'"
    >Max. Stay</clr-dg-column
  >
  <clr-dg-column [clrDgField]="'min_stay_minutes'" [clrDgColType]="'number'"
    >Min. Stay</clr-dg-column
  >
  <clr-dg-column
    [clrDgField]="'reservable_period_minutes'"
    [clrDgColType]="'number'"
    >Reservable Period</clr-dg-column
  >
  <clr-dg-column
    [clrDgField]="'buffer_period_minutes'"
    [clrDgColType]="'number'"
    >Buffer Period</clr-dg-column
  >
  <clr-dg-column
    [clrDgField]="'free_amendment_period_minutes'"
    [clrDgColType]="'number'"
    >Free Amendment Period</clr-dg-column
  >
  <clr-dg-column
  [clrDgField]="'spacing_period_minutes'"
  [clrDgColType]="'number'"
  >Spacing Period</clr-dg-column
  >
  <clr-dg-column
  [clrDgField]="'time_granularity_minutes'"
  [clrDgColType]="'number'"
  >Time Granularity</clr-dg-column
  >
  <clr-dg-column
  [clrDgField]="'no_show_expiry_minutes'"
  [clrDgColType]="'number'"
  >No Show Expiry</clr-dg-column
  >
  <clr-dg-row *clrDgItems="let config of configs" [clrDgItem]="config">
    <clr-dg-action-overflow>
      <button
        [id]="'viewConfig-' + config.id"
        class="action-item"
        (click)="viewConfig(config)"
      >
        View
      </button>
      <button
        [id]="'editConfig-' + config.id"
        class="action-item"
        (click)="editConfig(config)"
        [disabled]="config.id.toUpperCase() === 'DISABLED'"
      >
        Edit
      </button>
      <button
        [id]="'deleteConfig-' + config.id"
        class="action-item"
        (click)="deleteConfig(config.id)"
        [disabled]="config.id.toUpperCase() === 'DISABLED'"
      >
        Delete
      </button>
    </clr-dg-action-overflow>

    <clr-dg-cell>{{ config.id }}</clr-dg-cell>
    <clr-dg-cell>{{ config.max_stay_minutes }}</clr-dg-cell>
    <clr-dg-cell>{{ config.min_stay_minutes }}</clr-dg-cell>
    <clr-dg-cell>{{ config.reservable_period_minutes }}</clr-dg-cell>
    <clr-dg-cell>{{ config.buffer_period_minutes }}</clr-dg-cell>
    <clr-dg-cell>{{ config.free_amendment_period_minutes }}</clr-dg-cell>
    <clr-dg-cell>{{ config.spacing_period_minutes }}</clr-dg-cell>
    <clr-dg-cell>{{ config.time_granularity_minutes }}</clr-dg-cell>
    <clr-dg-cell>{{ config.no_show_expiry_minutes }}</clr-dg-cell>
<!--
    <clr-dg-row-detail *clrIfExpanded class="clr-row">
      <div class="clr-col-3">
        <div class="card">
          <div class="card-block">
            Config assigned to {{ getConfigLocations(config.id) }}
          </div>
        </div>
      </div>
    </clr-dg-row-detail> -->

  </clr-dg-row>
</clr-datagrid>
