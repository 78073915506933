import { Reservation, ReservationStatus } from 'src/app/interfaces/reservation';

import { Component } from '@angular/core';
import { ReservationsService } from '../services/reservations.service';

@Component({
  selector: 'app-reservations-list',
  templateUrl: './reservations-list.component.html',
  styleUrls: ['./reservations-list.component.css'],
})
export class ReservationsListComponent {
  reservations: Reservation[] = [];

  constructor(private _reservationsService: ReservationsService) {}

  ngOnInit() {
    this._reservationsService.getReservations().subscribe((resp) => {
      this.reservations = resp.data;
      console.log(this.reservations)
    });
  }

  getReservationStatusColour(status: ReservationStatus) {
    let colour
    if (status == 'PENDING') {
      colour = 'orange'
    } else if (status == 'CONFIRMED') {
      colour = 'green'
    } else if (status == 'CANCELLED') {
      colour = 'red'
    } else {
      colour = 'grey'
    }
    return colour
  }

  getReservationDuration(startTime: Date, endTime: Date) {
      // All reservations are in integers of minutes
    let differenceMilliseconds = new Date(endTime).valueOf() - new Date(startTime).valueOf()
    let differenceMinutes = differenceMilliseconds/(1000 * 60)
    return differenceMinutes
  }

  cancelReservation() {

  }

  reassignChargingSpaceForReservation() {

  }
}
