import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfigService } from 'src/app/app-config.service';
import { ReservationArrayResponse } from 'src/app/interfaces/response';

@Injectable({
  providedIn: 'root',
})
export class ReservationsService {
  private API_GATEWAY = '';

  constructor(
    private _http: HttpClient,
    private _appConfig: AppConfigService,
  ) {
    this.API_GATEWAY = this._appConfig.getConfig().API_GATEWAY;
  }

  getReservations(): Observable<ReservationArrayResponse> {
    const url = `${this.API_GATEWAY}/reservations`;
    return this._http.get<ReservationArrayResponse>(url);
  }
}
